import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  films
}) => (
  <div>
    <section id="header">
        <div className="inner">
            <span className="icon major fa-cloud"></span>
            <h1>{title}</h1>
            <p>{subheading}</p>
        </div>
    </section>
    <section id="one" className="main style1">
      <div className="grid-wrapper">
          <div className="col-6">
              <header className="major">
                  <h2>{mainpitch.title}</h2>
              </header>
              <p>{mainpitch.description}</p>
          </div>
          <div className="col-6">
              <span className="image fit"><img src={pic01} alt="" /></span>
          </div>
      </div>
    </section>

    <section id="two" className="main style2">
      <div className="grid-wrapper">
          <div className="col-6">
              <ul className="major-icons">
                  <li><span className="icon style1 major fa-code"></span></li>
                  <li><span className="icon style2 major fa-bolt"></span></li>
                  <li><span className="icon style3 major fa-camera-retro"></span></li>
                  <li><span className="icon style4 major fa-cog"></span></li>
                  <li><span className="icon style5 major fa-desktop"></span></li>
                  <li><span className="icon style6 major fa-calendar"></span></li>
              </ul>
          </div>
          <div className="col-6">
            <header className="major">
                <h2>{heading}</h2>
            </header>
            <p>{description}</p>
            <h1>Star Wars Films</h1>
            <div>
              {films.map(film => (
                <li key={film.id}><Link to={film.title.toLowerCase().replace(/\s+/g, '-')}>{film.title}</Link></li>
              ))}
            </div>
          </div>
      </div>
    </section>    

    <section id="three" className="main style1 special">
      <Features gridItems={intro.blurbs} />
      <div className="columns">
        <div className="column is-12 has-text-centered">
          <Link className="btn" to="/products">
            See all products
          </Link>
        </div>
      </div>
    </section>

  <section id="four" className="main style2 special">
    <div className="container">
      <h3 className="has-text-weight-semibold is-size-2">
        Latest stories
      </h3>
      <BlogRoll />
      <div className="column is-12 has-text-centered">
        <Link className="btn" to="/blog">
          Read more
        </Link>
      </div>
    </div>
  </section>
</div>

)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  films: PropTypes.array, 
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { allFilms } = data.swapi

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        films={allFilms} 
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    swapi: PropTypes.shape({
      allFilms: PropTypes.array,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
    swapi {
      allFilms(orderBy: episodeId_ASC) {
        id
        title
      }
    }
  }
`
